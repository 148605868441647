/*--------------------------GENERAL--------------------------*/
* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.text-black::placeholder {
  color: black !important;
}

.text-black {
  color: black !important;
}

.text-red {
  color: #c53048 !important;
}

.text-gray {
  color: rgb(78, 77, 77) !important;
}

.text-lightgray {
  color: #495057 !important;
}

.text-small {
  font-size: 0.9rem;
}

#fade-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0.8;
  display: block;
  z-index: 10000;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dark-red {
  color: #65192c !important;
}
/*--------------------------NAVBAR--------------------------*/
.navbar-red {
  background-color: #c53048;
  height: 4.8vh;
}

.rtm-logo {
  width: 14.64vw;
}

.rtm-search {
  width: 3.36vw;
}

.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}

.nav-item {
  color: #c53048;
  font-weight: 400;
  font-size: 20px;
  height: 6vh;
  border-width: 1px;
  border-right-style: inset;
}

.nav-links a {
  color: #c53048 !important;
  padding: 0.5rem 0.7rem !important;
  text-decoration: none;
}

.nav-links a:hover {
  background-color: #c53048 !important;
  border-radius: 3rem;
  color: #ffffff !important;
  font-weight: 400;
  text-decoration: none;
  font-size: 20px;
}

.dropdown a {
  color: #c53048 !important;
}

.dropdown-divider {
  border-top: 1px solid #c53048 !important;
}

.dropdown a:hover {
  background-color: #c53048 !important;
  border-radius: 3rem;
  color: #ffffff !important;
}

.navbar-expand .navbar-nav .dropdown-menu {
  width: -webkit-fill-available !important;
  min-width: min-content;
}

.dropdown-menu a {
  font-size: clamp(1.1rem, 1.15vw, 1.25rem);
}

.navitem-noborder {
  border-right-style: none;
}
/*-----------------------------BANNER---------------------------*/
.banner {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  position: relative;
  width: 100%;
  background-color: #dee1ea;
  overflow: hidden;
}

.banner-sm {
  height: 17vh;
}
.banner h3 {
  margin-top: 5vh;
}

.banner-md {
  height: 21vh;
}

.banner-lg {
  height: 25vh;
}

#banner-icon {
  position: absolute;
  z-index: 0;
}

.banner-text {
  color: #bf2c4a;
  font-size: 3rem;
  width: 100%;
  flex-grow: 1;
}

.greet-sm {
  font-size: 2rem;
}

/*--------------------------BACKGROUND--------------------------*/
.rtm-background {
  width: 47vw;
  margin-top: -62vh;
  z-index: 0;
}

.rtm-background-gray {
  position: fixed;
  width: 100%;
  height: 40vh;
  margin-top: -15%;
  background-color: #d2d3d6;
}

.rtm-background-gray-top {
  margin-bottom: 3rem;
  height: 25vh;
  background-color: #d2d3d6;
}

.cardtitle-operacoes {
  background-color: #d2d3d6 !important;
}

.operacoes-filler {
  height: 100%;
}
/*--------------------------CARD--------------------------*/
.card {
  width: 82%;
  z-index: 1;
  margin-left: 9%;
  margin-top: 5%;
  margin-bottom: 3%;
  border-radius: 20px 20px 20px 20px !important;
  border: 1px solid rgb(197, 48, 72) !important;
}

.card-title {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  z-index: 999;
  margin-top: 6vh;
  font-size: clamp(2.5rem, 2.5vw, 5rem) !important;
  text-align-last: center;
  color: #c53048;
  background-color: #ffffff;
  font-weight: 400 !important;
}

.orders-title {
  font-size: clamp(1.75rem, 1.75vw, 3rem) !important;
  color: #c53048;
  font-weight: 400 !important;
}

.card-orders {
  min-width: -webkit-fill-available !important;
  margin: 0 !important;
}

#card-bulk-file .form-group {
  margin: 2rem;
}

/*--------------------------FORM/INPUT--------------------------*/
.form-font {
  font-size: clamp(1.25rem, 1.25vw, 1.4rem);
  font-weight: 500;
  color: #555555;
}

.form-font-radio {
  font-size: 1.3rem;
  color: #9d9d9e;
}

.form-footer {
  font-weight: 500;
  color: #9d9d9e;
}

.input-radio {
  width: auto !important;
}

.input-text {
  font-size: clamp(1.1rem, 1.15vw, 1.25rem);
  font-weight: 500;
  color: #c53048;
}

.input-modal {
  color: #555 !important;
}

.form-parties p {
  font-size: 2rem;
  font-weight: 500;
  color: #c53048;
}

.choose-file-button {
  background-color: #dee1ea !important;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 25px;
}

.choose-file-button:hover {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.input-file-label {
  border-top: 2px solid #c53048;
  width: 50%;
  margin: 0.5rem;
}

.validation-error-message {
  margin: 2px 0;
  padding: 3px;
  border-radius: 4px;
  background-color: #e5bbc0;
  color: #c53048;
  font-size: large;
  font-weight: 600;
}

/*--------------------------MODAL--------------------------*/
.modal-dialog {
  margin-top: 9% !important;
  margin-left: 14% !important;
  margin-right: 14% !important;
  min-width: -webkit-fill-available;
}

.modal-content {
  border: 1px solid rgb(197, 48, 72) !important;
}

.modal-title {
  font-weight: 100 !important;
  font-size: clamp(2.5rem, 2.5vw, 2.6rem) !important;
  padding-top: 1vh;
  height: 12vh;
  background-color: #c53048 !important;
}

.modal-body {
  background-color: #e5bbc0;
}

.modal-inputbg {
  background-color: #e5bbc0 !important;
  width: 90% !important;
  color: black;
}

.modal-footer {
  background-color: #e5bbc0;
}

.modal-orders input {
  height: 5vh !important;
  width: 28vw !important;
}

.alert {
  margin-bottom: 0rem !important;
}

.label-modal-orders {
  color: #313131;
}

.status-aprovado {
  color: #017971 !important;
}

.status-recusado {
  color: #bf2c4a !important;
}

.status-registrado {
  color: #313131;
}

#error-modal {
  width: 100%;
}

.error-modal-title {
  font-weight: 300 !important;
  height: 100%;
  background-color: transparent !important;
  color: #c53048 !important;
  text-align: center;
}

.error-modal-body {
  color: rgb(197, 48, 72);
  font-size: large;
  text-align: center;
}

#myTable {
  width: 90% !important;
  margin-left: 5% !important;
}

/*--------------------------TABLE--------------------------*/
#table-op {
  width: 90% !important;
  border-collapse: collapse;
  margin: auto !important;
  font-size: 0.9em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

th,
td {
  vertical-align: middle !important;
  padding: 0.5rem;
}

#table-op th {
  border-right: 1px white solid;
  text-align: center;
}

.table-header {
  background-color: #c53048;
  height: 8vh;
  font-size: 1rem;
  font-weight: 400;
  color: white;
  vertical-align: middle !important;
  border-radius: 20px !important;
}

.table-body {
  background-color: #dee1e9;
}

td.td {
  white-space: pre !important;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  vertical-align: middle !important;
  justify-content: space-evenly !important;
}

.table-bunks {
  width: -webkit-fill-available;
}

.thead-dashboard tr th {
  font-weight: 600;
  color: gray;
  white-space: nowrap;
}

.tbody-dashboard tr td {
  color: #3a3a3a !important;
  white-space: nowrap !important;
}

.tbody-dashboard tr td:first-of-type {
  color: #c53048 !important;
}

.table-subtitle {
  color: gray;
  font-weight: 600;
}

.table-dashboard {
  width: 95%;
  overflow: auto;
  margin-left: 1rem;
}

.bl-gray {
  border-left: 2px solid #dee2e6 !important;
}

.bg-gray {
  background-color: #dee2e677 !important;
}

/*--------------------------ICONTILE--------------------------*/
.tiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
}

.tiles a {
  text-decoration: none;
  color: #912640;
}

.tiles a:hover {
  text-decoration: none;
  color: #65192c;
}

.tile {
  border-radius: 16px;
  background-color: #c53048;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-align: center;
  width: 200px;
  margin: 2rem;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.tile:hover {
  cursor: pointer;
  box-shadow: 0px 15px 20px rgba(197, 48, 72, 0.5);
  color: #fff;
  transform: translateY(-7px);
}

.tile-icon {
  color: #912640;
  font-size: 4rem;
}

.tile-text {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
}
/*--------------------------BOTÕES--------------------------*/

.btn > a {
  text-decoration: none !important;
  color: inherit;
}

.btn > a:hover {
  text-decoration: none !important;
  color: inherit;
}

.btn-primary {
  margin-right: 1rem;
  height: 5vh;
  width: 10vw;
  border-color: #c53048 !important;
  background-color: #c53048 !important;
  border-radius: 30px !important;
  padding: 0.2rem !important;
}

.btn-info {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 18px !important;
  padding: 0 1rem !important;
  height: 5vh;
  margin-left: 20px;
  border-radius: 10px !important;
}

.btn-secondary {
  height: 5vh;
  width: 10vw;
  color: #c53048 !important;
  border-color: #c53048 !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
  padding: 0.2rem !important;
}

.btn-danger {
  height: 5vh;
  width: 10vw;
  border-color: #c53048 !important;
  background-color: #c53048 !important;
  border-radius: 30px !important;
  padding: 0.2rem !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.radio-special {
  display: none;
}

.radio-special + label {
  border-radius: 20px;
  margin: auto;
  background: #ddd;
  height: 20px;
  width: 20px;
}

.radio-special:checked + label {
  background: url('assets/x-lg.svg') #373753 no-repeat;
}

.buttons {
  position: relative;
  width: 100%;
  min-height: 4vh;
}

#reject-button {
  background-color: #dee1ea !important;
  color: black;
  border: none;
}

#approve {
  position: absolute;
  right: 10%;
  bottom: calc(-16px - 1.25rem - 2.5vh);
}

#cancel {
  position: absolute;
  bottom: calc(-16px - 1.25rem - 2.5vh);
  left: 10%;
}

/*--------------------------MEDIA QUERIES--------------------------*/
@media screen and (min-width: 1378px) and (max-width: 1799px) {
  .rtm-background {
    margin-top: -50vh;
  }
}

@media screen and (min-width: 1800px) and (max-width: 2700px) {
  .rtm-background {
    margin-top: -46vh;
  }
}

.left-form,
.right-form {
  width: 100%;
  flex-grow: 1;
  padding: 16px;
}
/*-------------------------- FUND INFO --------------------------*/
input,
select {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: #c53048 2px solid !important;
  padding: 0 !important;
}

input[type='text']:focus {
  border-color: rgba(197, 48, 72, 0.1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(197, 48, 72, 0.1);
  outline: 0 none;
}

#application-type {
  width: 100%;
  font-size: 1.25rem;
  color: #495057;
  line-height: 1.5;
}

.application-type-bulk {
  width: 50% !important;
  font-size: 1.25rem;
  color: #495057;
  line-height: 1.5;
  display: grid;
}

#input-file {
  width: 50%;
}

.card-bulk {
  text-align: -webkit-center !important;
}

.text-sm {
  font-size: 1rem;
}

.fund-info {
  border: #bf2c4a 1.5px solid;
  border-radius: 12px;
  position: relative;
  padding: 2rem;
  margin-top: 2rem;
}

.fund-title {
  background-color: white;
  color: #bf2c4a;
  font-size: 1.4rem;
  position: absolute;
  top: -1.2rem;
  padding: 0 1rem;
  left: 10%;
  text-align: center;
}

.fund-footer {
  background-color: white;
  color: #bf2c4a;
  font-size: 0.8rem;
  position: absolute;
  bottom: -0.4rem;
  padding: 0 0.3rem;
  right: 10%;
  text-align: center;
}

table#fund-info-table {
  margin: auto;
  white-space: pre !important;
}

td.input-text {
  min-width: 180px;
  font-size: 16px;
}

td.read-only {
  font-size: 16px;
  padding: 0.3rem;
  min-width: 200px;
  color: #555555;
}

.refresh:hover {
  cursor: pointer;
}

#spinner {
  margin: auto;
  text-align: center;
}
/*-------------------------- LOGIN PAGE --------------------------*/
#rtm-logo-login {
  height: 100px;
}

.navbar-login {
  padding: 2vh 5vw !important;
}

.login-card {
  padding: 1rem 2rem;
  position: relative;
}

.login-card .card-title {
  margin: 0;
  padding: 0 1rem;
  position: absolute;
  right: 10%;
  top: -28px;
  font-weight: 300 !important;
}

#forgot-pass {
  padding: 1rem 1rem 0 1rem;
  color: #c53048;
  font-weight: 400;
}

.login-card label {
  font-size: 1.5rem;
}

.z-100 {
  z-index: 100 !important;
}

.text-muted {
  color: #9d9d9e !important;
  font-size: 1rem !important;
}
/*-------------------------- MAIN INDEX PAGE --------------------------*/
.content {
  padding: 3rem 10vw;
}

.content-title {
  color: #c5c6cb;
}

/*-------------------------- REDOC --------------------------*/
.search-input {
  padding-left: 1rem !important;
}

redoc-wrap input {
  border: none !important;
}

.no-margin {
  margin-top: 0vh !important;
}
/*-------------------------- CHARTS --------------------------*/
.chart-title h1 {
  text-align: center;
  font-weight: 400;
  color: gray;
  font-size: clamp(1.2rem, 1.45vw, 8rem);
}

.charts {
  text-align: -webkit-center;
}

/*-------------------------- TABS --------------------------*/
.tabs-orders {
  width: 90%;
  align-self: center;
  margin: auto;
}

.tabs-orders a:hover {
  color: #bf2c4a;
}

.tabs-orders a {
  color: #6c757c;
}

.tabs-orders a.active {
  color: #bf2c4a !important;
}

/*-------------------------- ANIMATIONS --------------------------*/

.fade-in {
  -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*-------------------------- DASHBOARD --------------------------*/

.dashboard-card {
  position: realative;
}

.dashboard-card-loading {
  padding: 12px;
  padding-top: 0;
  margin: none !important;
  font-size: clamp(2rem, 1.5vw, 3rem) !important;
  text-align-last: center;
  color: #c53048;
  background-color: #ffffff;
  font-weight: 400;
}

#dashboard-card-title {
  padding: 12px;
  padding-top: 0;
  margin: none !important;
  position: absolute;
  right: 5%;
  top: 0;
  transform: translateY(-50%);
  font-size: clamp(2.5rem, 2.5vw, 5rem) !important;
  text-align-last: center;
  color: #c53048;
  background-color: #ffffff;
  font-weight: 400;
}

.dashboard-filter {
  border: 1px #dee2e6 solid;
  border-radius: 12px;
  margin: none;
  min-height: 80%;
  margin-bottom: 1rem;
}

.dashboard-filter-list {
  max-height: 23vh;
  color: #495057;
  list-style: none !important;
  margin: 0.5rem;
  padding: 0.5rem;
  overflow-y: auto;
}

.dashboard-filter-list > li {
  line-height: 2rem;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 4px;
  font-style: italic;
}

.dashboard-filter-list > li:hover {
  cursor: pointer;
  margin: none;
  background-color: #e5bbc0;
}

.dashboard-filter-list > li.active {
  background-color: #e5bbc0;
}

.dashboard-filter-title {
  font-size: 1.6rem;
  color: #c53048;
  font-weight: 400;
}

.icon-border {
  border: #c53048 1px solid;
  border-radius: 4px;
}

.downloadButton {
  display: block;
  height: auto;
  cursor: pointer;
  color: #333;
  font-size: 17px;
  position: relative;
  text-transform: uppercase;
  line-height: 1;
}

.downloadButton::after {
  content: '';
  width: 0;
  height: 1px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background: #333;
  transition: all 0.4s ease;
}

.downloadButton:hover::after {
  width: 100%;
}

.modal-body {
  background-color: #fef3f5;
}

.modal-inputbg {
  background-color: #fef3f5 !important;
  width: 90% !important;
  color: black;
}

label {
  margin-bottom: 0 !important;
}

.form-control {
  margin-top: -1%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fef3f5 inset !important;
}

.center-data {
  text-align: center;
}

.details-link {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.details-link * {
  fill: #912640;
}

.details-link:hover {
  text-decoration: underline;
}

.details-sub {
  display: block;
  color: #666;
  font-size: 11px;
  margin-top: 5px;
}

.without-data {
  margin: 30px 0;
  font-size: 30px;
  font-weight: 400;
}

#portability-form > div > .form-group > input {
  padding: 0.5rem !important;
  margin-top: 0.5rem !important;
  line-height: 2rem !important;
  border-radius: 8px !important;
  height: 2.5rem !important;
  font-size: 0.9rem;
}

#portability-form > div > div > .form-group > input {
  display: inline;
  padding: 0.5rem !important;
  margin-top: 0.5rem !important;
  line-height: 2rem !important;
  border-radius: 8px !important;
  height: 2.5rem !important;
  font-size: 0.9rem;
}

.portability-input {
  display: inline;
  padding: 0.5rem !important;
  margin-top: 0.5rem !important;
  line-height: 2rem !important;
  border-radius: 8px !important;
  height: 2.5rem !important;
  font-size: 0.9rem;
}

#portability-form > div > .form-group > label {
  padding-left: 0.5rem !important;
  font-weight: 600;
  color: #c53048;
  font-size: 0.9rem;
}

#portability-form > div > div > .form-group > label {
  font-weight: 600;
  color: #c53048;
  margin-bottom: 0.5rem !important;
  font-size: 0.9rem;
}

.download-link {
  text-decoration: underline;
}

.download-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.btn-add-order {
  background-color: #6c757c;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.btn-add-order:hover {
  box-shadow: none;
  cursor: pointer;
  background-color: #c53048;
  color: white;
}
option {
  font-weight: 600;
}

.order-cards {
  border: #c53048 1px solid;
  border-radius: 8px;
  position: relative;
  padding-left: 2rem !important;
}

.order-cards > .order-number {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: #c53048;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
}

.portability-back-icon {
  color: white;
  font-weight: 600;
  background-color: #c53048;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  transform: translate(50%, 50%);
  cursor: pointer;
}

#notification-bell {
  position: relative;
}

.notification-number {
  position: absolute;
  color: white;
  font-weight: 600;
  background-color: #c53048;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  font-size: smaller;
  text-align: center;
  top: 0;
  transform: translate(70%, 0);
}

.notifications {
  padding: 0.5rem;
}

.notifications:hover {
  background-color: #c53048 !important;
  border-radius: 3rem;
  color: #ffffff !important;
  font-weight: 400;
  text-decoration: none;
  padding: 0.5rem;
  cursor: pointer;
}

.show-notifications {
  position: relative;
}

.notifications-list {
  min-width: 20rem !important;
}

.notifications-list > .notification-link {
  text-decoration: none !important;
  color: rgb(78, 77, 77);
}

.notifications-list > a > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification-item {
  font-size: 0.78rem;
  padding: 0 1rem;
}

.notifications-list > a > ul > li > hr {
  color: #65192c;
}

.notifications-list > a > ul > hr:last-child {
  display: none;
}

.no-min-height {
  min-height: 0 !important;
}

.portability-details {
  line-height: 2.3rem;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #c53048 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #c53048 !important;
}

.inform-adm {
  width: 15vw;
  height: 100%;
}

.add-pco:hover {
  cursor: pointer;
}

.order-details {
  line-height: 2rem;
  padding-left: 0;
}

.order-item {
  list-style: none;
}

h5.text-red {
  margin-top: 2rem;
  padding-left: 0;
  cursor: pointer;
}

.portability-order-table {
  line-height: 0.8rem;
  font-size: 0.95rem;
}

.portability-order-table > tr > td {
  padding: 0.4rem;
}

#search-icon {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  background-color: #c53048;
  color: white;
  padding: 0.5rem;
}

#search-icon:hover {
  cursor: pointer;
}

.x-form {
  box-sizing: border-box;
  border: none !important;
  border-bottom: #c53048 2px solid !important;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  appearance: none;
  width: 100%;
  font-size: 0.9rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline;
  padding: 0.5rem !important;
  margin-top: 0.5rem !important;
  line-height: 2rem !important;
  border-radius: 8px !important;
  height: 2.5rem !important;
}

.portability-check > h5:hover {
  cursor: auto;
}

.btn-block {
  width: 15vw !important;
}

.orders-table {
  font-size: small;
}

.portability-form-divider {
  background: linear-gradient(to right, #c53048, white);
  opacity: 0.5;
  border-radius: 25%;
}

.portability-party {
  margin-top: 4rem !important;
}

.portability-btn {
  width: 100%;
  padding: 1.2rem !important;
}

.select-port {
  box-sizing: border-box;
  border-bottom: #c53048 2px solid !important;
  appearance: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline;
  padding: 0.3rem 0.5rem !important;
  margin-top: 0.5rem !important;
  line-height: 2rem !important;
  border-radius: 8px !important;
  height: 2.5rem !important;
  font-size: 0.9rem;
}

.notification-dot {
  padding: 0 !important;
}

.bg-gray {
  background-color: #f7f7f7;
}

.br-gray {
  border-right: 1px solid #dee2e6;
}

.add-admin-id {
  position: relative;
}

.add-admin-id:hover {
  cursor: pointer;
}

#investorAdminIdToParty {
  background-color: white;
  width: 500px;
  height: 3.5rem;
  padding: 0 0.5rem !important;
  overflow: visible;
  opacity: 1;
  z-index: 100;
}

.show-input-adm {
  position: absolute;
  bottom: 50%;
  right: 50%;
  z-index: 100;
  transform: translateX(50%);
}

.show-input-adm > .btn {
  margin-top: 2rem;
}

.orders-table {
  position: relative;
}

.bg-opacity {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.09);
}

.bulk-port {
  font-weight: 500;
}

.bulk-port > input {
  padding: 1rem !important;
  border-radius: 8px !important;
}

.input-text-port {
  color: #c53048;
  font-size: 1.1rem;
}

.position-relative {
  position: relative !important;
}

.choose-standard {
  color: #c53048;
  padding: 1rem;
}

#select-file .file-option {
  font-weight: normal;
  font-size: 1rem;
  bottom: 0;
}

#select-file .file-option:hover {
  background-color: #fef3f5 !important;
  color: #c53048 !important;
  border-radius: 0;
}

.drop-down-wrapper {
  background-color: #fef3f5;
  position: absolute;
  z-index: 100;
  padding: 0.5rem 1rem;
  border-radius: 0 0 8px 8px;
}

.drop-down-wrapper a {
  display: block;
}

.drop-down-wrapper a:hover {
  background-color: #fef3f5 !important;
  color: #c53048 !important;
  border-radius: 0;
}

.portability-li {
  font-size: 0.9rem !important;
}
